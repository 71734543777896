import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
  useBoolean,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import {
  CreateTimeTrackingItemMutationVariables,
  useCreateTimeTrackingItemMutation,
  useStartTimerMutation,
  useStopTimerMutation,
} from "@src/__generated__/graphql";
import {
  CapacityAllocationItem,
  Task,
  TimeTrackingBudgetItem,
  TimeTrackingWorkType,
  User,
  useValidateCreateTimeTrackingItemMutation,
} from "@src/__generated__/urql-graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import { onError } from "@src/utils/apollo";
import { WrapComponent } from "@src/utils/components/WrapComponent";
import { now, toApiDate } from "@src/utils/dates";
import { useStore } from "@src/utils/hooks";
import { isTrackingEnabled } from "@src/utils/time-tracking/trackingEnabled";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useMemo } from "react";
import { TaskModel } from "../models";

type TimeTrackingButtonProps = {
  taskId: Task["id"];
  timeTrackingSettings: Pick<
    TimeTrackingBudgetItem,
    "tracking_enabled" | "tracking_enabled_from" | "tracking_enabled_to"
  >;
  note?: string | null;
  positions?: TaskModel["positions"];
  trackForDate?: Date;
  trackForUser?: User["id"];
  trackForPosition?: TimeTrackingWorkType["id"];
  capacityAllocationItemId?: CapacityAllocationItem["id"];
  onTimeTrackingChange?: (running: boolean) => void;
  customButtonTitle?: string;
  label?: string;
} & (
  | ({ variant?: "button" } & ButtonProps)
  | ({ variant?: "iconButton" } & Omit<IconButtonProps, "aria-label">)
);

enum TimeTrackingState {
  TrackingEnabled = "tracking-enabled",
  TrackingDisabled = "tracking-disabled",
  OtherRunningTask = "other-running-task",
  NotAssigned = "not-assigned",
}

const TimeTrackingButton: FunctionComponent<TimeTrackingButtonProps> = ({
  positions,
  taskId,
  timeTrackingSettings,
  trackForDate,
  trackForUser,
  trackForPosition,
  variant = "button",
  onTimeTrackingChange,
  capacityAllocationItemId,
  label,
  note,
  customButtonTitle,
  ...props
}) => {
  const {
    timeTrackingStore,
    authStore,
    changeTimeTrackingWorkTypeModalStore,
    resourcePlanningDayStore,
    resourcePlanningWeekStore,
  } = useStore();
  const [menuState, setMenuState] = useBoolean(false);
  const [{ fetching: isValidating }, validate] =
    useValidateCreateTimeTrackingItemMutation();

  const [create, { loading: createLoading }] =
    useCreateTimeTrackingItemMutation({
      onCompleted(data) {
        if (!trackForPosition && userPositions.length > 1) {
          setMenuState.toggle();
        }
        start({
          variables: { time_tracking_item_id: data.createTimeTrackingItem.id },
        });
      },
      ...onError(),
    });

  const [start, { loading: startLoading }] = useStartTimerMutation({
    onCompleted(data) {
      if (data.startTimer) {
        onTimeTrackingChange?.(true);
        timeTrackingStore.refetch?.();
      }
    },
    ...onError(),
  });

  const [stop, { loading: stopLoading }] = useStopTimerMutation({
    onCompleted(data) {
      if (data?.stopTimer) {
        onTimeTrackingChange?.(false);
        timeTrackingStore.refetch?.();
        timeTrackingStore.updateTrackedTimeInListing(taskId);
      }
    },
    ...onError(),
  });

  const toggleTimer = async () => {
    if (
      !trackForPosition &&
      userPositions.length > 1 &&
      !timeTrackingStore.isTimerRunning
    ) {
      return setMenuState.toggle();
    }

    if (!timeTrackingStore.isTimerRunning && capacityAllocationItemId) {
      const { data } = await validate({
        user_id: trackForUser ?? authStore.user!.id,
        task_id: taskId,
        time_tracking_work_type_id: trackForPosition!,
        capacity_allocation_item_id: capacityAllocationItemId,
      });

      if (!data?.validateCreateTimeTrackingItem) {
        changeTimeTrackingWorkTypeModalStore.modalState.onOpen({
          task_id: taskId,
          capacity_allocation_item_id: capacityAllocationItemId,
          user_id: trackForUser ?? authStore.user!.id,
          time_tracking_work_type_id: trackForPosition!,
          onSubmit: (new_time_tracking_work_type_id) => {
            create({
              variables: {
                ...variables,
                time_tracking_work_type_id: new_time_tracking_work_type_id,
              },
            });
          },
        });
        return;
      }
    }

    const shouldStartTracking = timeTrackingStore.isTimerRunning
      ? // Tracking inside planning
        capacityAllocationItemId
        ? !timeTrackingStore.trackingForAllocation(capacityAllocationItemId)
        : !timeTrackingStore.trackingForTask(taskId)
      : true;

    if (shouldStartTracking) {
      await create({
        variables: {
          ...variables,
          time_tracking_work_type_id: !!trackForPosition
            ? trackForPosition
            : userPositions[0].timeTrackingWorkType?.id ?? "",
        },
      });
    } else {
      await stop({
        variables: {
          time_tracking_item_id: timeTrackingStore.runningEntry!.id,
        },
      });
    }

    if (capacityAllocationItemId) return;
    if (window.location.pathname.includes("resource-planning/my-week")) {
      resourcePlanningWeekStore.fetchDailyView({ backgroundUpdate: true });
      return;
    }
    if (window.location.pathname.includes("resource-planning")) {
      resourcePlanningDayStore.fetchDailyView({ backgroundUpdate: true });
    }
  };

  const getTooltip = () => {
    switch (timeTrackingState) {
      case TimeTrackingState.NotAssigned:
        return t`You cannot track time on task where you are not assigned.`;
      case TimeTrackingState.OtherRunningTask:
        return t`You have another tracker running. Starting a new one will pause the previous one.`;
      case TimeTrackingState.TrackingDisabled:
        return t`Time tracking for today is not enabled`;
      case TimeTrackingState.TrackingEnabled:
        return undefined;
    }
  };

  const variables: Omit<
    CreateTimeTrackingItemMutationVariables,
    "time_tracking_work_type_id"
  > = {
    user_id: trackForUser ?? authStore.user!.id,
    task_id: taskId,
    tracked_time: 0,
    tracked_for_date: toApiDate(trackForDate ?? now()),
    capacity_allocation_item_id: capacityAllocationItemId,
    note: note ?? "",
  };

  const userPositions: TaskModel["positions"] = useMemo(() => {
    return (
      positions?.filter(
        (position) => position.user?.id === authStore.user?.id,
      ) ?? []
    );
  }, [positions, authStore.user?.id]);

  const timeTrackingState: TimeTrackingState = useMemo(() => {
    if (
      timeTrackingStore.isTimerRunning &&
      (!timeTrackingStore.trackingForTask(taskId) ||
        (capacityAllocationItemId &&
          !timeTrackingStore.trackingForAllocation(capacityAllocationItemId)))
    ) {
      return TimeTrackingState.OtherRunningTask;
    }

    if (
      positions !== undefined &&
      !positions.some((position) => position.user?.id === authStore.user?.id)
    ) {
      return TimeTrackingState.NotAssigned;
    }

    if (!isTrackingEnabled(timeTrackingSettings, trackForDate)) {
      return TimeTrackingState.TrackingDisabled;
    }

    return TimeTrackingState.TrackingEnabled;
  }, [
    taskId,
    timeTrackingSettings,
    trackForDate,
    capacityAllocationItemId,
    timeTrackingStore.runningEntry,
  ]);

  const isTrackingDisabled =
    timeTrackingState === TimeTrackingState.NotAssigned ||
    timeTrackingState === TimeTrackingState.TrackingDisabled;

  return (
    <Menu isOpen={menuState} onClose={setMenuState.off}>
      <WrapComponent
        if={!!getTooltip() || !!label}
        with={(children) => (
          <Tooltip label={getTooltip() || label} shouldWrapChildren>
            <Box>{children}</Box>
          </Tooltip>
        )}
      >
        <CustomMenuButton
          timeTrackingState={timeTrackingState}
          title={customButtonTitle}
          isTrackingDisabled={isTrackingDisabled}
          handleClick={toggleTimer}
          buttonVariant={variant}
          isLoading={
            startLoading || stopLoading || createLoading || isValidating
          }
          {...props}
        />
      </WrapComponent>

      <MenuList>
        <MenuGroup title={t`Choose position`}>
          {userPositions.map((position) =>
            position.timeTrackingWorkType?.id ? (
              <MenuItem
                key={position.id}
                onClick={() => {
                  if (!position.timeTrackingWorkType?.id) return;
                  create({
                    variables: {
                      ...variables,
                      time_tracking_work_type_id:
                        position.timeTrackingWorkType.id,
                    },
                  }).then(() => setMenuState.off);
                }}
              >
                {position.timeTrackingWorkType?.title}
              </MenuItem>
            ) : null,
          )}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

type CustomMenuButtonProps = Omit<
  TimeTrackingButtonProps,
  "onClick" | "taskId" | "timeTrackingSettings"
> & {
  timeTrackingState: TimeTrackingState;
  isLoading: boolean;
  isTrackingDisabled: boolean;
  handleClick: () => Promise<void>;
  buttonVariant: TimeTrackingButtonProps["variant"];
};

const CustomMenuButton = observer(function CustomMenuButton({
  timeTrackingState,
  isLoading,
  title,
  handleClick,
  isTrackingDisabled,
  buttonVariant,
  ...props
}: CustomMenuButtonProps) {
  const { timeTrackingStore } = useStore();
  return buttonVariant === "button" ? (
    <MenuButton
      as={Button}
      isDisabled={isTrackingDisabled}
      isLoading={isLoading}
      leftIcon={
        <Icon
          name={
            timeTrackingStore.isTimerRunning &&
            timeTrackingState !== TimeTrackingState.OtherRunningTask
              ? "pause-circle"
              : "play"
          }
          variant="solid"
        />
      }
      onClick={handleClick}
      {...props}
    >
      {title ??
        (timeTrackingStore.isTimerRunning &&
        timeTrackingState !== TimeTrackingState.OtherRunningTask
          ? t`Stop Tracking`
          : t`Start Tracking`)}
    </MenuButton>
  ) : (
    <MenuButton
      as={IconButton}
      aria-label={t`play/pause timer`}
      icon={
        <Icon
          pointerEvents="none"
          name={
            timeTrackingStore.isTimerRunning &&
            timeTrackingState !== TimeTrackingState.OtherRunningTask
              ? "pause-circle"
              : "play"
          }
          variant="solid"
        />
      }
      isDisabled={isTrackingDisabled}
      isLoading={isLoading}
      onClick={handleClick}
      {...props}
    />
  );
});

export default observer(TimeTrackingButton);
