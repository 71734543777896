import {
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { ModalConfirm } from "@src/components/ui-kit";
import { DescriptionTab } from "@src/components/widgets/Modals/ModalCommunication/components/tabs/DescriptionTab";
import { LinkedTasksTab } from "@src/components/widgets/Modals/ModalCommunication/components/tabs/LinkedTasksTab";
import { TaskAttachmentsTab } from "@src/components/widgets/Modals/ModalCommunication/components/tabs/TaskAttachmentsTab";
import {
  TTaskTab,
  TaskTab,
} from "@src/components/widgets/Modals/ModalCommunication/components/tabs/TaskTab";
import { TaskTabIdEnum } from "@src/constants/tasks";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { Fragment, useMemo, useState } from "react";
import { CommunicationTabParent } from "./communication-tab-parent";

export const TaskTabs = observer(function TaskTabs() {
  const { authStore } = useStore();
  const [newId, setNewId] = useState<TaskTabIdEnum | undefined>(undefined);
  const TASK_TABS: TTaskTab[] = useMemo(() => {
    return (
      [
        {
          id: TaskTabIdEnum.Description,
          label: () => t`Description`,
          icon: "file-02",
          component: <DescriptionTab />,
          userTypesWithAccess: ["internal", "client", "partner"],
        },
        {
          id: TaskTabIdEnum.Communication,
          label: () => t`Communication`,
          icon: "message-chat-square",
          component: <CommunicationTabParent />,
          userTypesWithAccess: ["internal", "client", "partner"],
        },
        {
          id: TaskTabIdEnum.Attachments,
          label: () => t`Files`,
          icon: "paperclip",
          component: <TaskAttachmentsTab />,
          userTypesWithAccess: ["internal", "client", "partner"],
        },
        {
          id: TaskTabIdEnum.LinkedTasks,
          label: () => t`Linked tasks`,
          icon: "check-done-01",
          component: <LinkedTasksTab />,
          userTypesWithAccess: ["internal"],
        },
      ] as TTaskTab[]
    ).filter((tab) => tab.userTypesWithAccess.includes(authStore.user?.type!));
  }, [authStore.user?.type]);

  const { taskDetailModalStore: store } = useStore();
  const discardModal = useDisclosure();

  const currentTabIndex = TASK_TABS.findIndex(
    (tab) => tab.id === store.activeTabId.value,
  );

  return (
    <Fragment>
      <Tabs
        index={currentTabIndex}
        onChange={(index) => {
          if (store.commentChanged) {
            setNewId(index);
            discardModal.onOpen();
          } else {
            store.activeTabId.set(TASK_TABS[index].id);
          }
        }}
      >
        <TabList
          overflowX={{
            base: "scroll",
            md: undefined,
          }}
          overflowY={{
            base: "hidden",
            md: undefined,
          }}
        >
          {TASK_TABS.map((tab) => (
            <TaskTab key={tab.id} tab={tab} />
          ))}
        </TabList>

        <TabPanels minH="400px">
          {TASK_TABS.map((tab) => (
            <TabPanel key={tab.id}>{tab.component}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
      <ModalConfirm
        isOpen={discardModal.isOpen}
        onClose={discardModal.onClose}
        onConfirm={() => {
          store.commentChanged = false;
          if (!newId) return;
          store.activeTabId.set(newId);
          setNewId(undefined);
        }}
        confirmBtnTitle={t`Discard changes`}
        destructive
      >
        <Trans>
          Your have unsaved changes. Would you like to discard them?
        </Trans>
      </ModalConfirm>
    </Fragment>
  );
});
