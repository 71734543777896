import { t } from "@lingui/macro";
import { UserScopeEnum } from "@src/__generated__/graphql";
import { AppStore } from "@src/stores/AppStore";
import { action, computed, makeObservable, observable } from "mobx";
import { CommunicationTab } from "./CommunicationTab";
import { TTaskTab } from "./TaskTab";

type TabInfo = Pick<
  TTaskTab,
  "id" | "label" | "component" | "userTypesWithAccess"
> & {
  userScope: UserScopeEnum | undefined;
};
export class CommunicationTabParentStore {
  private static InternalTabInfo: TabInfo = {
    id: 0,
    label: () => t`Internal`,
    component: <CommunicationTab userType="internal" />,
    userTypesWithAccess: ["internal", "client", "partner"],
    userScope: undefined,
  };
  private static ClientTabInfo: TabInfo = {
    id: 1,
    label: () => t`Client`,
    component: <CommunicationTab userType="client" />,
    userTypesWithAccess: ["client"],
    userScope: UserScopeEnum.Client,
  };
  private static PartnerTabInfo: TabInfo = {
    id: 2,
    label: () => t`Partner`,
    component: <CommunicationTab userType="partner" />,
    userTypesWithAccess: ["partner"],
    userScope: UserScopeEnum.Partner,
  };

  @observable.ref selectedTab = 0;

  constructor(private appStore: AppStore) {
    makeObservable(this);
  }

  @computed get availableTabs(): TabInfo[] {
    if (!this.appStore.authStore.user?.type) return [];
    return {
      internal: [
        CommunicationTabParentStore.InternalTabInfo,
        CommunicationTabParentStore.ClientTabInfo,
        CommunicationTabParentStore.PartnerTabInfo,
      ],
      client: [CommunicationTabParentStore.ClientTabInfo],
      partner: [CommunicationTabParentStore.PartnerTabInfo],
    }[this.appStore.authStore.user?.type];
  }

  @computed get selectedTabInfo(): TabInfo {
    return this.availableTabs[this.selectedTab];
  }

  @action.bound setSelectedTab(tab: number) {
    this.selectedTab = tab;
  }
}
